.details_container {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  width: 100%;
  font-family: 'Poppins';
  gap: 20px;
  padding-bottom: 50px;

  p {
    margin: 0px;
  }
  .heading {
    height: 49px;
    line-height: 49px;
    padding-left: 18px;
    vertical-align: middle;
    font-size: 16px;
    font-weight: 600;
    background-color: var(--supportive-grey-01);
  }
  .label {
    font-size: 14px;
    color: var(--supportive-grey-05);
    letter-spacing: 0.98px;
    font-weight: 500;
  }

  .info {
    font-size: 14px;
    color: var(--primary-navy);
    &.bold {
      font-weight: 600;
    }
    &.YES {
      color: #31b500;
    }
    &.NO {
      color: var(--red);
    }
  }
  .container {
    border: 1px solid var(--supportive-grey-02);
    border-radius: 4px;
    width: 100%;
    padding-bottom: 45px;
    .consignment_options_container {
      display: grid;
      grid-template-columns: 25% 25% 25% 25%;
      padding-top: 30px;
      padding-left: 50px;
      padding-right: 50px;
      row-gap: 25px;
      column-gap: 20px;
      @media (max-width: 1400px) and (min-width: 768px) {
        grid-template-columns: 50% 50%;
      }
      @media (max-width: 768px) {
        padding-left: 25px;
        grid-template-columns: 60% 40%;
        column-gap: 0px;
      }
      .option_row {
        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: 6px;
        .label {
          color: var(--primary-navy);
          letter-spacing: 0px;
        }
        .icon {
          color: var(--green);
        }
      }
    }

    .cash_on_delivery_info {
      display: flex;
      flex-direction: row;
      gap: 50px;
      padding-top: 45px;
      padding-left: 50px;
      padding-right: 50px;
      @media (max-width: 768px) {
        padding-left: 25px;
      }
      .info_section {
        display: flex;
        flex-direction: row;
        gap: 10px;
      }
    }
    .cash_on_delivery_description {
      padding-top: 40px;
      padding-top: 40px;
      padding-left: 50px;
      padding-right: 50px;
      @media (max-width: 768px) {
        padding-left: 25px;
      }
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }

  .insurance_temperature_container {
    display: flex;
    gap: 20px;
    @media (max-width: 1200px) {
      flex-direction: column;
    }
    .container {
      border: 1px solid var(--supportive-grey-02);
      border-radius: 4px;
      width: 100%;
      padding-bottom: 45px;
      .temperature_info {
        display: flex;
        justify-content: space-between;
        padding-top: 45px;
        padding-right: 55px;
        padding-left: 50px;
        gap: 20px;
        @media (max-width: 768px) {
          padding-left: 25px;
        }
        @media (max-width: 1600px) {
          flex-direction: column;
        }
        .temperature_row {
          display: flex;
          width: 240px;
          .temperature_label {
            min-width: 150px;
            width: 150px;
            margin-right: auto;
          }
          .temperature {
            display: flex;

            .cold {
              color: var(--primary-blue);
              padding-top: 2px;
            }
            .hot {
              padding-top: 4px;
              color: var(--red);
            }
            .info {
              width: 70px;
              text-align: right;
            }
          }
        }
      }
    }
    .insurance_info {
      padding-top: 45px;
      padding-right: 55px;
      padding-left: 50px;
      @media (max-width: 768px) {
        padding-left: 25px;
      }
      display: flex;
      flex-direction: row;
      gap: 30px;
      align-items: center;
    }
  }
}
