.page-num {
  width: 32px;
  height: 32px;
  background-color: var(--supportive-grey-01);
  color: var(--primary-navy);
  border: none;
  border-radius: 3px;
  margin-left: 10px;
  cursor: pointer;

  &.current {
    font-weight: bold;
    border: 1px solid var(--primary-blue);
    background-color: var(--primary-blue);
    color: var(--primary-white);
    cursor: default;
  }
  &:hover {
    border: 1px solid var(--primary-blue);
    color: var(--primary-blue);
    font-weight: bold;
    &.current {
      color: var(--primary-white);
    }
  }
}
.filler {
  width: 32px;
  height: 32px;
  background-color: var(--supportive-grey-01);
  border: none;
  border-radius: 3px;
  margin-left: 10px;
}
.next {
  width: 32px;
  height: 32px;
  background-color: var(--supportive-grey-01);
  color: var(--primary-navy);
  border: none;
  border-radius: 3px;
  margin-left: 10px;
  cursor: pointer;
  &:disabled {
    cursor: default;
  }
  &:hover:enabled {
    border: 1px solid var(--primary-blue);
    color: var(--primary-blue);
    font-weight: bold;
  }
}
