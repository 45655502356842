.display_job {
  font-family: 'Poppins';
  display: flex;
  flex-direction: column;
  margin-left: 90px;
  margin-right: 90px;
  gap: 32px;
  @media (max-width: 1600px) {
    margin-left: 5%;
  }
  @media (max-width: 768px) {
    padding-top: 10px;
    margin-left: 16px;
    margin-right: 16px;
  }
  .header {
    display: flex;
    flex-direction: row;
    gap: 30px;
    position: relative;
    .back_button {
      min-width: 340px;
      position: sticky;
      left: 20px;
    }
    .error_container {
      width: 100%;
    }
    @media (max-width: 768px) {
      flex-direction: column;
      .back_button {
        align-self: center;
        width: 90vw;
      }
      .error_container {
        align-self: center;
        width: 95%;
      }
    }
  }
  .job_information {
    display: flex;
    flex-direction: row;
    gap: 30px;
    @media (max-width: 768px) {
      flex-direction: column;
    }
    .sidebar {
      position: sticky;
      left: 20px;
      z-index: 1;
    }

    .info_tabs {
      width: 100%;
      @media (max-width: 768px) {
        align-self: center;
      }
      .tabs {
        display: flex;
        flex-direction: row;
        font-size: 16px;
        font-weight: 600;
        width: 100%;
        border-bottom: 1px solid var(--supportive-grey-02);

        .tab {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 200px;
          height: 64px;
          background-color: #00101f0a;
          color: #656f78;
          cursor: pointer;
          text-align: center;
          &:hover {
            color: var(--primary-blue);
          }
          @media (max-width: 1600px) {
            width: 12vw;
            font-size: 14px;
          }
          @media (max-width: 768px) {
            width: 100%;
            font-size: 16px;
          }
          &.active_tab {
            background-color: var(--primary-blue);
            color: var(--primary-white);
          }
        }
      }
    }
  }
}
