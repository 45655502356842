:root {
  --primary-blue: #1a00e3;
  --primary-navy: #00101f;
  --primary-white: #ffffff;

  --supportive-grey-01: #f5f5f6;
  --supportive-grey-02: #f0f0f0;
  --supportive-grey-03: #c4c4c4;
  --supportive-grey-04: #4a545f;
  --supportive-grey-05: #656f78;

  --shade-blue-01: #2e16fc;
  --shade-blue-02: #1d0bb2;
  --blue-gradient: linear-gradient(246deg, #1900e6 0%, #00294e 100%);

  --red: #d2232a;
  --red-low-opacity: #d2232a27;
  --red-shade-01: #fc5959;
  --red-shade-02: #ffa5a5;
  --red-shade-03: #f8ddde;

  --green: #62e254;
  --green-shade: #afffa6;

  --yellow: #fccd73;
  --yellow-shade: #fcf173;

  --orange: #ff8355;
  --orange-shade: #ffd3a6;
}

/* @media (prefers-color-scheme: dark) {
  :root {

  }
} */
