.loading_container {
  font-family: 'Poppins';
  font-size: 20px;
  display: flex;
  flex-direction: column;
  height: fit-content;
  margin-top: 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  .spinner {
    color: var(--primary-blue);
    height: 80px;
  }
}
