.overview_container {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  width: 100%;
  font-family: 'Poppins';
  gap: 20px;
  padding-bottom: 50px;

  p {
    margin: 0px;
  }
  .heading {
    height: 49px;
    line-height: 49px;
    padding-left: 18px;
    vertical-align: middle;
    font-size: 16px;
    font-weight: 600;
    background-color: var(--supportive-grey-01);
  }
  .label {
    font-size: 14px;
    color: var(--supportive-grey-05);
    letter-spacing: 0.98px;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 5px;
    position: relative;
    .info_icon {
      fill: var(--supportive-grey-05);
      width: 16px;
      height: 16px;
      &:hover {
        cursor: pointer;
        fill: var(--primary-blue);
      }
      &:active {
        fill: var(--primary-blue);
      }
    }
    .info_icon:hover + .address_hover {
      display: block;
    }
    .info_icon:hover,
    .info_icon:active + .address_hover {
      display: block;
    }
    .address_hover {
      display: none;
      position: absolute;
      top: 30px;
      width: 240px;
      background: #f5f5f6;
      border-radius: 3px;
      box-shadow: 0px 6px 10px #00101f1f;
      z-index: 1;
      padding: 24px;
      .header {
        font-size: 14px;
        font-weight: 600;
        color: var(--primary-navy);
        margin-bottom: 5px;
      }
      .info {
        font-size: 14px;
        font-weight: 400;
        color: var(--primary-navy);
      }
    }
  }
  .info {
    font-size: 14px;
    color: var(--primary-navy);
  }
  .info_column {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-right: 20px;
  }

  .container {
    border: 1px solid var(--supportive-grey-02);
    border-radius: 4px;
    width: 100%;
    padding-bottom: 50px;
    .entities_info_container {
      display: grid;
      grid-template-columns: 30% 70%;
      row-gap: 50px;
      padding-top: 45px;
      padding-left: 50px;
      @media (max-width: 768px) {
        padding-left: 25px;
      }
      @media (max-width: 1200px) {
        gap: 25px;
        grid-template-columns: 40% 50%;
      }
    }

    .consignment_info_container {
      display: grid;
      grid-template-columns: 16.667% 16.667% 16.667% 16.667% 16.667% 16.667%;
      padding-top: 45px;
      padding-left: 50px;
      @media (max-width: 768px) {
        padding-left: 25px;
      }
      @media (max-width: 1200px) {
        gap: 25px;
        grid-template-columns: 50% 40%;
      }
    }
    .description {
      padding-top: 45px;
      padding-left: 50px;
      @media (max-width: 768px) {
        padding-left: 25px;
      }
    }

    .dangerous_goods_info_container {
      display: flex;
      flex-direction: column;
      gap: 5px;
      padding-top: 45px;
      padding-left: 50px;
      @media (max-width: 768px) {
        padding-left: 25px;
      }
      .option_row {
        margin-left: 1px;
        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: 6px;
        .icon {
          width: 6px;
          height: 6px;
        }
        .goods {
          font-size: 14px;
        }
      }
    }

    .package_dimensions_info_container {
      padding-top: 45px;
      padding-left: 50px;
      padding-right: 50px;
      @media (max-width: 768px) {
        padding-left: 25px;
        padding-right: 25px;
      }

      .table_headers {
        display: grid;
        margin-right: 15px;
        grid-template-columns: 10% 40% 17% 17% 16%;
        @media (max-width: 768px) {
          width: 100%;
        }
        .header {
          font-size: 14px;
          font-weight: 500;
          color: var(--supportive-grey-05);
          letter-spacing: 0.98px;
          @media (max-width: 1000px) {
            font-size: 12px;
          }
          &.first {
            padding-left: 20px;
            @media (max-width: 768px) {
              padding-left: 10px;
            }
            &.short {
              display: none;
            }
            &.long {
              display: block;
            }
            @media (max-width: 1650px) {
              &.short {
                display: block;
              }
              &.long {
                display: none;
              }
            }
          }
        }
      }
      .table_body {
        margin-top: 22px;
        .row {
          display: grid;
          grid-template-columns: 10% 40% 17% 17% 16%;
          align-items: center;
          min-height: 48px;
          @media (max-width: 768px) {
            width: 100%;
          }
          &.coloured {
            background-color: var(--supportive-grey-01);
          }
          .item {
            font-size: 14px;
            padding-right: 20px;
            &.first {
              padding-left: 20px;
              @media (max-width: 768px) {
                padding-left: 10px;
              }
            }
          }
        }
      }
    }
  }
}
