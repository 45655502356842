@import url('../../themes.css');

.button_component {
  display: flex;
  position: relative;
  align-items: center;
  width: 100%;
  &.xs {
    width: 176px;
  }

  .primary_button {
    cursor: pointer;
    height: 48px;
    width: 100%;
    color: white;
    background-color: var(--primary-blue);
    border-radius: 3px;
    border: 0px;
    font-weight: 500;
    font-size: 16px;
    font-family: 'Poppins';
    &.xs {
      height: 40px;
      width: 107px;
    }
  }

  .secondary_button {
    cursor: pointer;
    height: 48px;
    width: 400px;
    color: var(--primary-navy);
    background-color: var(--primary-white);
    border-radius: 3px;
    border: 1px solid var(--primary-blue);
    font-weight: 500;
    font-size: 16px;
    font-family: 'Poppins';
    &.xs {
      height: 40px;
      width: 176px;
      font-size: 14px;
    }
    &.back {
      padding-left: 20px;
    }
  }

  .chevron {
    color: var(--primary-blue);
    position: absolute;
    margin-left: 20px;
    &.disabled {
      color: var(--supportive-grey-03);
    }
  }

  .primary_button:disabled {
    background-color: var(--supportive-grey-02);
    color: var(--supportive-grey-03);
  }
  .primary_button:disabled:hover {
    background-color: var(--supportive-grey-02);
    color: var(--supportive-grey-03);
  }

  .secondary_button:disabled {
    background-color: var(--primary-white);
    border: 1px solid var(--supportive-grey-03);
    color: var(--supportive-grey-03);
  }
  .secondary_button:disabled:hover {
    background-color: var(--primary-white);
    border: 1px solid var(--supportive-grey-03);
    color: var(--supportive-grey-03);
  }
}

.button_component:hover {
  .primary_button {
    background-color: #2e15fc;
  }

  .secondary_button {
    background-color: var(--primary-blue);
    border: 1px solid var(--primary-blue);
    color: var(--primary-white);
  }

  .chevron {
    color: var(--primary-white);
  }
}

.button_component:active {
  .primary_button {
    background-color: var(--shade-blue-02);
  }

  .secondary_button {
    background-color: var(--shade-blue-01);
    border: 1px solid var(--shade-blue-01);
    color: var(--primary-white);
  }

  .chevron {
    color: var(--primary-white);
  }
}
