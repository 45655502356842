.dropdown_component {
  font-family: 'Poppins';
  font-size: 14px;
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;

  .select_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    border: 1px solid var(--supportive-grey-03);
    border-radius: 3px;
    background-color: var(--primary-white);

    &.active {
      border: 1px solid var(--primary-blue);
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
    }
    .item {
      margin-left: 24px;
    }
    .arrow {
      background-color: transparent;
      border: none;
      color: var(--primary-blue);
      height: 18px;
      width: 14px;
      margin-right: 16px;
    }
    &.disabled {
      opacity: 0.5;
    }
  }
  .select_box:hover {
    border: 1px solid var(--primary-blue);
    &.disabled {
      cursor: default;
      border: 1px solid var(--supportive-grey-03);
    }
  }
  .options_list {
    position: absolute;
    width: 100%;
    margin-top: -1px;
    display: flex;
    border: 1px solid var(--primary-blue);
    border-radius: 3px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    flex-direction: column;
    .item {
      display: flex;
      height: 43px;
      align-items: center;
      padding-left: 21px;
      background-color: var(--primary-white);
    }
    .item:hover {
      background-color: var(--primary-blue);
      color: var(--primary-white);
    }
    .item:active {
      background-color: var(--primary-blue);
      color: var(--primary-white);
    }
  }
}
