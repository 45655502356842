.sidebar {
  font-family: 'Poppins';
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 10px;
  p {
    margin: 0px;
  }
  .label {
    font-size: 14px;
    color: var(--supportive-grey-05);
    letter-spacing: 0.98px;
    font-weight: 500;
    &.temperature_label {
      width: 150px;
      margin-right: auto;
    }
  }
  .heading {
    font-size: 16px;
    font-weight: 600;
    color: var(--primary-navy);
  }
  .info {
    font-size: 14px;
    color: var(--primary-navy);
    &.bold {
      font-weight: 600;
    }
  }
  .info_row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .name_status_container {
    width: 300px;
    height: 80px;
    background-color: var(--supportive-grey-01);
    padding: 20px;
    @media (max-width: 1600px) {
      width: 19vw;
      min-width: 240px;
      height: fit-content;
    }
    @media (max-width: 768px) {
      align-self: center;
      width: 90%;
    }
    .job_name {
      display: flex;
      flex-direction: row;
      gap: 10px;
      margin-bottom: 8px;
      @media (max-width: 1600px) and (min-width: 769px) {
        flex-direction: column;
      }
      .job {
        font-size: 28px;
        font-weight: 600;
      }
      .reference {
        font-size: 28px;
        color: var(--primary-blue);
      }
    }
    .job_status {
      display: flex;
      flex-direction: row;
      gap: 10px;
      align-items: center;
      .label {
        font-size: 14px;
        color: var(--supportive-grey-05);
      }
    }
  }
  .about_container {
    width: 300px;
    height: fit-content;
    padding: 20px;
    background-color: var(--supportive-grey-01);
    display: flex;
    flex-direction: column;
    gap: 14px;
    @media (max-width: 1600px) {
      width: 19vw;
      min-width: 240px;
    }
    @media (max-width: 768px) {
      align-self: center;
      width: 90%;
    }
    .about {
      font-weight: 600;
      font-size: 16px;
    }
  }

  .attachment_container {
    display: flex;
    flex-direction: column;
    background-color: var(--supportive-grey-01);
    padding-left: 20px;
    padding-top: 16px;
    padding-bottom: 18px;
    padding-right: 20px;
    min-height: 24px;
    width: 300px;
    @media (max-width: 1600px) {
      width: 19vw;
      min-width: 240px;
    }
    @media (max-width: 768px) {
      align-self: center;
      width: 90%;
    }
  }
}
