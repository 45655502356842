.status_component {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Poppins';
  font-size: 14px;
  height: 32px;
  width: 119px;
  border-radius: 50px;
  border-width: 1px;
  border-style: solid;

  &.delivered {
    background-color: var(--green-shade);
    border-color: var(--green);
  }
  &.pending {
    background-color: var(--orange-shade);
    border-color: var(--orange);
  }
  &.active {
    background-color: var(--yellow-shade);
    border-color: var(--yellow);
  }
  &.cancelled {
    background-color: var(--red-shade-02);
    border-color: var(--red);
  }
  &.none {
    background-color: var(--primary-white);
    border-color: var(--primary-blue);
  }
}
