.schedule_container {
  width: 100%;
  height: 73.8vh;
  font-family: 'Poppins';
  position: relative;
  .schedule {
    padding-bottom: 80px;
  }
}
.no_schedule {
  padding-top: 30px;
  padding-left: 20px;
}
