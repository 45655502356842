.stop_container {
  font-family: 'Poppins';
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 28px;
  padding-bottom: 28px;
  position: relative;
  width: 100%;
  @media (max-width: 1000px) {
    align-items: flex-start;
  }

  p {
    margin: 0px;
  }
  .heading {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 12px;
    &.Delivery {
      color: var(--primary-blue);
    }
  }
  .icon {
    margin-left: 31px;
    margin-right: 51px;
    @media (max-width: 1000px) {
      margin-left: 20px;
      margin-right: 18px;
    }
  }
  .timeline {
    position: absolute;
    background-color: #00101f24;
    width: 3px;
    left: 62px;
    z-index: -1;
    &.top {
      top: 0;
      height: 50%;
    }
    &.bottom {
      height: 50%;
      bottom: 0;
    }
    @media (max-width: 1000px) {
      left: 51px;
      &.top {
        height: 25%;
      }
      &.bottom {
        height: 75%;
      }
    }
  }
  .stop {
    display: flex;
    flex-direction: column;
    width: 100%;
    .stop_info {
      align-items: center;
      display: grid;
      grid-template-columns: 40% 60%;
      width: 100%;
      @media (max-width: 1000px) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 90%;
        gap: 10px;
      }
      .info {
        font-size: 14px;
        &.entity {
          font-weight: 600;
        }
        &.address {
          max-width: 312px;
          margin-right: 62px;
          @media (max-width: 1000px) {
            margin-right: 0px;
          }
        }
      }
      .estimated_actual {
        display: flex;
        flex-direction: row;
        gap: 70px;
        @media (max-width: 1200px) {
          flex-direction: column;
          gap: 10px;
        }
        .time_block {
          display: flex;
          flex-direction: column;
          .label {
            font-size: 14px;
            font-weight: 500;
            letter-spacing: 0.98px;
            color: var(--supportive-grey-05);
            margin-bottom: 5px;
          }
          .date_and_time {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 4px;
            width: 180px;
            .calendar {
              color: var(--primary-blue);
            }
            .clock {
              color: var(--primary-blue);
              margin-left: 16px;
            }
          }
        }
      }
    }
  }
}
