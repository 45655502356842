@import url('../../themes.css');

.login {
  display: flex;
  height: 100vh;
  color: var(--primary-navy);
  font-family: 'Poppins';
  @media (max-width: 850px) {
    flex-direction: column;
  }

  .logo {
    position: relative;
    display: flex;
    flex-direction: column;
    background-image: url(../../assets/ital-globe.jpeg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    align-items: center;
    justify-content: center;
    color: white;
    flex: 1;
    @media (max-width: 850px) {
      background-position: center 60%;
    }
    .logoImage {
      z-index: 1;
      @media (max-width: 850px) {
        width: 225px;
      }
    }
  }

  .form {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    @media (max-width: 850px) {
      flex: 2;
      align-items: flex-start;
    }

    .error_container {
      width: 100%;
      position: absolute;
      top: -30px;
    }

    .content {
      display: flex;
      flex-direction: column;
      position: relative;

      @media (max-width: 850px) {
        zoom: 0.8;
        -moz-transform: scale(0.8);
      }

      input {
        height: 48px;
        width: 386px;
      }

      .forgotlink {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 12px;
        margin-bottom: 20px;
        column-gap: 5px;
        p {
          font-weight: 400;
          font-size: 14px;
          @media (max-width: 850px) {
            font-size: 16px;
          }
        }
        a {
          color: var(--primary-navy);
          font-weight: 600;
          font-size: 14px;
          text-decoration: underline;

          @media (max-width: 850px) {
            font-size: 16px;
          }
        }
        a:hover {
          color: var(--primary-blue);
        }
      }

      .subtitle {
        font-size: 16px;
        @media (max-width: 850px) {
          font-size: 18px;
        }
      }

      .title {
        font-size: 42px;
        font-weight: 600;
        margin-bottom: -15px;
        @media (max-width: 850px) {
          font-size: 44px;
        }
      }

      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus,
      input:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px white inset !important;
      }
    }
  }

  .disableForm {
    pointer-events: none;
  }
}
