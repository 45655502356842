.jobs {
  display: flex;
  flex-direction: column;
  gap: 10px;

  a {
    &:hover {
      filter: contrast(4%);
    }
  }
}
