.navbar {
  font-family: 'Poppins';
  header {
    z-index: 2;
    position: fixed;
    top: 0;
    width: 100%;
    box-shadow: 0px 3px 6px #00000029;
    background: var(--primary-white);

    .top {
      display: flex;
      height: 70px;

      .left {
        display: flex;
        align-items: center;
        width: 100%;
        @media (max-width: 768px) {
          width: 100%;
          justify-content: space-around;
        }

        .logo {
          cursor: pointer;
          max-width: 350px;
          min-width: 200px;
          width: 100%;
          margin-left: 10%;
          padding-right: 10%;
          @media (max-width: 768px) {
            margin-left: 0px;
            width: 260px;
            padding-right: 0px;
          }
        }

        .mobile_logout {
          height: 28px;
          width: 28px;
          border-left: none;
          cursor: pointer;
          @media (min-width: 769px) {
            display: none;
          }
        }
        .mobile_logout:hover {
          color: var(--primary-blue);
        }
      }

      .right {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        white-space: nowrap;
        @media (max-width: 768px) {
          display: none;
        }

        .user {
          font-size: 14px;
          text-align: end;
          margin-right: 35px;
          @media (max-width: 1000px) {
            font-size: 1.4vw;
          }
        }

        .logout {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 70px;
          max-width: 240px;
          width: 40%;
          border: none;
          border-left: 1px solid #f0f0f0;
          background: none;
          font-size: 14px;
          padding-right: 24px;
          padding-left: 24px;
          font-family: 'Poppins';
          cursor: pointer;
          .logout_symbol {
            height: 18px;
            width: 18px;
            margin-left: 8px;
          }
        }
        .logout:hover {
          color: var(--primary-blue);
        }
      }
    }
    .user {
      &.below {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-size: 14px;
        background-color: var(--primary-blue);
        width: 100%;
        height: 38px;
        color: var(--primary-white);

        @media (min-width: 769px) {
          display: none;
        }
      }
    }
  }
  .content {
    margin-top: 120px;
  }
}
