@import url('../../themes.css');

.table {
  font-family: 'Poppins';
  font-size: 14px;
  width: 100vw;
  max-height: 63vh;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 1255px) {
    align-items: flex-start;
    width: 100%;
  }
  .table_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 120px;

    table {
      width: 90vw;
      display: table;
      flex-direction: column;
      border-collapse: collapse;
      @media (max-width: 1255px) {
        margin-left: 4.7vw;
        margin-right: 5vw;
      }
      @media (max-width: 768px) {
        margin-left: 4vw;
        margin-right: 4.6vw;
      }
      .header {
        height: 56px;
        th {
          text-align: start;
          color: var(--supportive-grey-05);
          font-weight: 500;
          padding-bottom: 20px;
          cursor: pointer;
          &.booked {
            padding-left: 20px;
            min-width: 100px;
            width: 100px;
          }
          &.our_ref {
            min-width: 140px;
            width: 140px;
          }
          &.your_ref {
            min-width: 140px;
            width: 140px;
          }
          &.shipper {
            min-width: 100px;
            width: 250px;
          }
          &.collection {
            min-width: 100px;
            width: 250px;
          }
          &.consignee {
            min-width: 100px;
            width: 250px;
          }
          &.delivery {
            min-width: 100px;
            width: 250px;
          }
          &.status {
            min-width: 120px;
          }

          &.status {
            cursor: default;
            padding-right: 18px;
          }
          .sort_icon_up {
            color: var(--supportive-grey-03);
            position: absolute;
            width: 12px;
            height: 12px;
            top: 12px;
            margin-left: 5px;
            &.sorted_header {
              color: var(--primary-blue);
            }
          }
          .sort_icon_down {
            position: absolute;
            width: 12px;
            height: 12px;
            top: 13px;
            margin-left: 5px;
            color: var(--supportive-grey-03);
            &.sorted_header {
              color: var(--primary-blue);
            }
          }
        }
      }

      tbody {
        height: 100%;
        max-height: 56vh;

        tr {
          height: 56px;
          &.coloured {
            background: var(--supportive-grey-01);
          }
          td {
            padding-right: 20px;
            &.our_ref {
              text-decoration-line: underline;
            }
            &.booking {
              padding-left: 20px;
            }
            .text {
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }

    .no_entity {
      display: flex;
      width: 100vw;
      height: 100%;
      justify-content: center;
      .info_container {
        background: var(--supportive-grey-01);
        display: flex;
        position: fixed;
        flex-direction: column;
        width: 90%;
        height: 56%;
        justify-content: center;
        align-items: center;
        @media (max-width: 768px) {
          justify-content: flex-start;
        }
        @media (max-width: 1255px) {
          text-align: center;
          justify-self: flex-start;
          left: 4vw;
          right: 4vw;
          width: 93%;
        }
        .info_icon {
          margin-top: 20px;
          height: 72px;
          width: 72px;
          color: var(--primary-blue);
          fill: var(--primary-blue);
        }
        h2 {
          padding-left: 20px;
          padding-right: 20px;
        }
        p {
          padding-left: 20px;
          padding-right: 20px;
          margin-top: -10px;
          margin-bottom: 15px;
        }
      }
      .button_container {
        width: 160px;
      }
    }
  }

  .footer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0px;
    padding-bottom: 25px;
    background-color: var(--primary-white);
    @media (max-width: 768px) {
      padding-bottom: 0px;
    }
    .table_bottom {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      height: 100px;
      width: 90vw;
      z-index: 1;
      @media (max-width: 768px) {
        flex-direction: column;
        justify-content: center;
        gap: 10px;
      }
    }
  }
}
