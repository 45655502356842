.attachments {
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-family: 'Poppins';
  font-size: 14px;
  padding-top: 20px;
  .attachment_row {
    display: flex;
    flex-direction: row;
    min-height: 24px;
    align-items: flex-start;
    gap: 8px;
    p {
      margin: 0px;
    }
    .download {
      cursor: pointer;
      color: var(--primary-blue);
      text-decoration: underline;
      overflow-wrap: break-word;
      max-width: 150px;
      @media (max-width: 1600px) and (min-width: 769px) {
        max-width: 8vw;
      }
    }
    .icon {
      height: 24px;
    }
    .size {
      white-space: nowrap;
    }

    .customs {
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 37px;
      height: 16px;
      background-color: var(--primary-navy);
      color: var(--primary-white);
      border-radius: 90px;
      font-size: 12px;
      text-transform: uppercase;
      margin-top: 3px;
    }
  }
}
