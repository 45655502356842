@import url('../../themes.css');

.input_component {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  label {
    font-size: 14px;
    margin-top: 24px;
    &.disabled {
      color: var(--supportive-grey-03);
    }
    @media (max-width: 850px) {
      font-size: 16px;
    }
  }

  .inner_input {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;

    input {
      padding-left: 13px;
      border: 1px solid var(--supportive-grey-03);
      border-radius: 3px;
      font-size: 16px;
      ::placeholder {
        color: transparent;
      }
    }

    input:focus {
      border: 1px solid var(--primary-blue);
      outline: none;
      border-radius: 3px;
    }

    input:disabled {
      border: 1px solid var(--supportive-grey-02);
      color: var(--supportive-grey-03);
    }

    .show_password {
      background-color: transparent;
      color: var(--primary-navy);
      position: absolute;
      height: auto;
      border: 0px;
      right: 18px;
      @media (max-width: 850px) {
        right: 16px;
        .eye {
          height: 20px;
        }
      }
    }

    .show_password:hover {
      color: var(--primary-blue);
    }
  }

  &.search {
    .inner_input {
      .magnifying_glass {
        position: absolute;
        width: 20px;
        height: 20px;
        left: 13px;
      }
      input {
        padding-left: 42px;
        background-color: var(--supportive-grey-01);
        height: 100%;
        width: 100%;

        border: none;
        color: var(--primary-navy);
      }
      input::placeholder {
        opacity: 1;
        color: var(--primary-navy);
      }
      input:focus {
        ~ .magnifying_glass {
          color: var(--primary-blue);
        }
      }
      input:disabled {
        opacity: 0.5;
        ~ .magnifying_glass {
          opacity: 0.5;
        }
      }
    }
  }
}
input::-ms-reveal,
input::-ms-clear {
  display: none;
}
.input_component:hover {
  .inner_input {
    input:enabled {
      border: 1px solid var(--primary-blue);
      margin-left: -1px;
      outline: none;
      border-radius: 3px;
      ~ .magnifying_glass {
        color: var(--primary-blue);
      }
    }
    input::placeholder {
      opacity: 1;
      color: var(--primary-navy);
    }
  }
}
