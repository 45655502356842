@import url('../../themes.css');

.error {
  display: flex;
  align-items: center;
  gap: 14px;
  width: 100%;
  min-height: 46px;
  border: 0px;
  border-radius: 3px;
  color: var(--red);
  background-color: var(--red-low-opacity);

  .error_message {
    font-weight: 400;
    font-size: 14px;
    .bold {
      font-weight: 600;
    }
    .underline {
      color: var(--red);
      text-decoration-line: underline;
      cursor: pointer;
    }
  }

  .symbol {
    width: 24px;
    height: 24px;
    margin-left: 12px;
  }
}
