@import url('../../themes.css');

.home {
  display: flex;
  font-family: 'Poppins';
  width: 100vw;

  .jobs {
    display: flex;
    position: relative;
    @media (max-width: 768px) {
      margin-top: 60px;
    }
    .header_background {
      margin-top: -90px;
      padding-top: 101px;
      display: flex;
      position: fixed;
      align-items: center;
      justify-content: center;
      background-color: var(--primary-white);
      width: 100%;
      height: 100px;
      z-index: 1;
      @media (max-width: 768px) {
        height: 150px;
      }

      .search_bar {
        display: flex;
        align-self: center;
        justify-content: space-between;
        align-items: center;
        width: 90%;
        height: 45px;
        border-bottom: 1px solid #d6d6d6;
        padding-bottom: 24px;

        @media (max-width: 768px) {
          width: 100%;
          flex-direction: column;
          height: 200px;
          border: none;
          margin-top: -50px;
          margin-left: 0px;
          margin-right: 0px;
        }

        .error_container {
          position: absolute;
          width: 90%;
          top: 55px;
          @media (max-width: 768px) {
            position: relative;
            top: 0px;
          }
        }

        .entity_display {
          width: 100%;
          display: flex;
          flex-direction: row;
          column-gap: 10px;
          font-size: 1.8vw;
          .jobs_for {
            font-weight: 600;
          }
          @media (max-width: 768px) {
            flex-direction: column;
            font-size: 20px;
            width: 90%;
            .jobs_for {
              margin-bottom: -15px;
            }
          }
        }
        .search_filter {
          display: flex;
          justify-content: flex-end;
          column-gap: 10px;
          width: 100%;

          .search_container {
            max-width: 307px;
            width: 100%;
            height: 41px;
          }
          .filter_container {
            max-width: 226px;
            width: 100%;
            height: 40px;
          }
          @media (max-width: 768px) {
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            row-gap: 14px;
            .search_container {
              height: 44px;
              max-width: 90%;
            }
            .filter_container {
              height: 44px;
              max-width: 90%;
            }
          }
        }
      }
    }

    .table_content {
      display: flex;
      justify-content: center;
      align-self: center;
      align-items: center;
      margin-top: 126px;
      width: 100vw;
      @media (max-width: 768px) {
        margin-left: 16px;
        margin-top: 180px;
      }
    }
  }
}
